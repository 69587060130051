/* globals Sentry, define */
import 'core-js/fn/object/entries'
import 'core-js/fn/object/values'
import 'intersection-observer'
import requirejs from 'requirejs'
import {getRjsConfig} from './rjs-config'
import * as sharedRegistry from './init/sharedRegistry'
import {initBeatEvents} from './bi/initBeatEvents'
import {BEATS} from './bi/constants'
import experimentFactory from 'santa-main-r/lib/lib/common/experimentFactory'
import getQueryUtils from 'santa-main-r/lib/lib/common/getQueryUtils.js'
import addExperimentsFromQuery from 'santa-main-r/lib/lib/common/addExperimentsFromQuery'
import isBotFn from 'santa-main-r/lib/lib/common/isBot'
import createLogger from './init/logger'
import createWorkerFactory from './init/platform/createWorker'
import iframesHandler from 'bolt-server/src/warmup/iframesHandler'
import {resourceHints, resourceHintsExtra} from './resource-hints'

window.santaBase = `${window.boltBase}/node_modules/wix-santa`
const {rendererModel, publicModel, location, navigator, performance = {}, isStreaming, serviceTopology, wixBiSession = {}, boltBase, santaBase, documentServicesModel} = window
window.wixBiSession = wixBiSession

const queryUtil = getQueryUtils(window)
window.queryUtil = queryUtil
const {getParameterByName, isParameterTrue} = queryUtil

rendererModel.runningExperiments = addExperimentsFromQuery(rendererModel.runningExperiments, queryUtil, 'viewer')

const isPreview = typeof publicModel === 'undefined'
const requestModel = {
    userAgent: navigator.userAgent,
    cookie: document.cookie,
    deviceType: !isPreview && publicModel.deviceInfo ? publicModel.deviceInfo.deviceType : 'desktop'
}
const rawUrl = location.href
const isInSSR = false

function getLogger() {
    if (isPreview) {
        return {
            error: console.error,
            appLoadingPhaseStart: console.log,
            appLoaded: console.log,
            appLoadingPhaseFinish: console.log
        }
    }
    return createLogger(Sentry, {rendererModel, publicModel, requestModel, rawUrl, boltBase, isInSSR, wixBiSession})
}

const logger = getLogger()
const experimentInst = experimentFactory.build(window)

const noop = () => {}

window.performance = performance
window.performance.mark = performance.mark || noop
window.performance.now = performance.now || (() => Date.now())

const carmiDebug = getParameterByName('debug') === 'all' ||
    isParameterTrue('ssrDebug') ||
    isParameterTrue('carmiDebug') ||
    getParameterByName('BoltSource').search(/^https?:\/\/localhost($|:|\/)/) === 0
const ds = isParameterTrue('ds')
const rjsConfig = getRjsConfig(boltBase, santaBase, serviceTopology, {carmiDebug, ds, query: location.search})
requirejs.config(rjsConfig)

define('mobx', [], () => ({
    runInAction: noop,
    action: noop,
    isObservableArray: noop
}))
define('mobx-react', [], () => ({
    observer: noop
}))

const CHUNK_NAMES = ['init', 'animations']

resourceHints(CHUNK_NAMES, window, boltBase, rendererModel, serviceTopology, requirejs, experimentInst)

requirejs(['lodash'], _ => {
    const initPromise = import(/*webpackChunkName: "init"*/ './init')
    const boltAnimationsPromise = import(/*webpackChunkName: "animations"*/ 'bolt-animations/src/warmupAnimations')
    const isBot = isBotFn(window)

    sharedRegistry.init()

    const reportBeatEvent = initBeatEvents(experimentInst, isBot)
    const {beatNumber, eventName} = BEATS.MAIN_R_LOADED
    reportBeatEvent(beatNumber, eventName)

    resourceHintsExtra(window, requirejs, experimentInst)

    initPromise.then(({init, createFunctionLibrary}) => {
        const isDebug = Boolean(getParameterByName('debug'))

        const fetchFunction = (url, options, dataType) => fetch(url, options || undefined)
            .then(res => {
                if (!res.ok && !_.get(options, 'allowErrors')) {
                    throw new Error(`Fetch failed. Status: ${res.status}. Reason: ${res.statusText}.`)
                }

                return res[dataType || 'json']()
            })

        const ssrModel = {
            isStreaming,
            isInSSR,
            boltInstanceFunctionLibrary: {
                failSsrRequest: e => {
                    throw e
                }
            }
        }

        const hostInstanceBatchingStrategy = function () { // eslint-disable-line func-style
            setImmediate(() => {
                this.$endBatch()
            })
        }

        const boltInstanceBatchingStrategy = null
        const functionLibrary = createFunctionLibrary({
            fetchFunction,
            requireFunction: requirejs,
            workerFunction: createWorkerFactory(isDebug),
            biReporter: null,
            boltAnimationsPromise,
            logger
        })

        return init({
            logger,
            ssrModel,
            hostInstanceBatchingStrategy,
            boltInstanceBatchingStrategy,
            functionLibrary,
            rendererModel,
            rawSeoTags: undefined,
            documentServicesModel,
            publicModel,
            isPreview,
            serviceTopology,
            requestModel,
            rawUrl,
            wixBiSession,
            reportBeatEvent,
            registerToIframesLoadEvent: iframesHandler.registerForEvents,
            renderFlags: window.renderFlags || {},
            isBot,
            isDebug,
            santaBase
        })
    }).then(({doneStagePromise}) => doneStagePromise).then(async result => {
        const {hostInstance, boltInstance, boltMain, hydrate, serverMarkup, indicator} = result
        const rootElement = document.getElementById('SITE_CONTAINER')
        await boltMain.renderClientSide(boltInstance, rootElement, hydrate, serverMarkup, indicator, logger)
        hostInstance.setRenderPhase(hydrate ? 'hydrate' : 'render')
    }).then(() => { //eslint-disable-line consistent-return
        iframesHandler.flushEvents()
        if (ds) {
            //TODO debug only
            const dsPromise = import('bolt-ds-adapter')
            return dsPromise.then(({createAdapter, createDocumentServices}) => { //eslint-disable-line promise/no-nesting
                const adapter = createAdapter(window.boltInstance)
                window.documentServices = createDocumentServices(adapter)
            })
        }
    })
})

